(function() {

  const MAIL_ADDRESS = "hello@marcosrgz.com";
  const MAIL = `Contact me!  ${MAIL_ADDRESS}`;
  const GITHUB = `Have a look at my GitHub repos!`;
  const LINKEDIN = `Visit my LinkedIn profile!`;
  
  const displays = [$("#display-main"), $("#display-bottom")];
  
  let currentText = MAIL;
  
  const showInDisplay = (text) => {
    if (currentText == text) return;
    currentText = text;
    displays.forEach(display => display.fadeOut(50, () => changeDisplayText(display, currentText)));
  };
  
  const changeDisplayText = (display, currentText) => {
    display.text(currentText);
    display.fadeIn();
  };
  
  $('#mail-main').on("mouseenter", function(){
    showInDisplay(MAIL);
  });
  
  $('#github-main').on("mouseenter", function(){
    showInDisplay(GITHUB); 
  });
  
  $('#linkedin-main').on("mouseenter", function(){
    showInDisplay(LINKEDIN);
  });
  
  $('#mail-bottom').on("mouseenter", function(){
    showInDisplay(MAIL);
  });
  
  $('#github-bottom').on("mouseenter", function(){
    showInDisplay(GITHUB); 
  });
  
  $('#linkedin-bottom').on("mouseenter", function(){
    showInDisplay(LINKEDIN);
  });
  
  showInDisplay('');

})();
